'use client';

import { faBug, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { PropsWithChildren, ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Icon } from './Icon';
import './Spinner.scss';

interface SpinnerProps extends PropsWithChildren {
	size?: 'xs' | 'sm' | 'lg' | '4x' | '1x' | '2x' | '3x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x' | undefined;
	className?: string;
	loading?: boolean | boolean[];
	message?: string;
	error?: Error;
}

export function Spinner(props: SpinnerProps): ReactNode {
	let className = 'iconRow';
	if (props.className) {
		className += ` ${props.className}`;
	}

	const size = props.size || 'lg';
	const title = props.message || 'Loading...';

	return (
		<>
			<Row className={className}>
				<Col className="text-center">
					<Icon icon={faSpinner} spin={true} title={title} size={size} />
				</Col>
			</Row>
		</>
	);
}

export function LoadingError(props: SpinnerProps): ReactNode {
	let className = 'iconRow';
	if (props.className) {
		className += ` ${props.className}`;
	}

	const size = props.size || '2x';

	// TODO: add tooltip with more details about the error

	return (
		<>
			<Row className={className}>
				<Col className="text-center">
					<Icon icon={faBug} title="Error loading data" size={size} />
				</Col>
			</Row>
		</>
	);
}
