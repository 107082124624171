'use client';

import { PropsWithChildren, ReactNode } from 'react';
import { Spinner } from '../../../../../components/Spinner/Spinner';
import ContentWithDefault from '../../../../../components/contentWithDefault/page';
import { useHasData } from './useHasData';

export interface LoadingContentProps extends PropsWithChildren {
	data?: unknown;
	loading?: boolean;
	loadingContent?: ReactNode;
	hasData?: boolean;
	noDataContent?: ReactNode;
}

/**
 * TODO: Look into switching to Suspense at some point
 *
 * (useSWR currently doesn't recommend it for fetching data)
 * https://swr.vercel.app/docs/suspense
 *
 * @param props
 * @returns
 */
export default function LoadingContent(props: LoadingContentProps): ReactNode {
	// Use hooks to reduce the amount of change
	const loading = Boolean(props.loading);
	const hasData = useHasData(props.data, props.hasData);

	if (loading) {
		return <ContentWithDefault content={props.loadingContent} defaultContent={<Spinner />} />;
	}

	if (!hasData) {
		return <ContentWithDefault content={props.noDataContent} />;
	}

	return props.children;
}
